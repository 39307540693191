import '@/commons';
import Vue from 'vue';
import { Debug } from '@/shared/utils/helpers';
import vuetify from '@/config/plugins/vuetify';
import Header from '@/layout/header';
import Footer from '@/layout/footer';

const header = Header;
const footer = Footer;

const DefaultPage = Vue.extend({
    setup() {
        return {};
    },
});
if (document.getElementById('default')) {
    const d = new DefaultPage({
        el: '#default',
        vuetify,
        delimiters: ['[{', '}]'],
    });
}
